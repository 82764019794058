import { gql } from '@apollo/client';

export const ESTADOS_GRAPHQL = gql`
  query {
    empresarial_estados {
      id
      ds_estado
      sg_estado
    }
  }
`;

export const REGIOES_GRAPHQL = gql`
  query getRegioes($estado: String!, $descricao: String!) {
    empresarial_regioes(estado: $estado, descricao: $descricao) {
      te_bairro
      ds_cidade
    }
  }
`;

export const ESPECIALIDADES_GRAPHQL = gql`
  query getEspecialidades($tipo: String, $estado: String!) {
    empresarial_especialidades(tipo: $tipo, estado: $estado) {
      id
      ds_especialidade
    }
  }
`;

export const EXAMES_GRAPHQL = gql`
  query getExames($search: String!) {
    empresarial_exames(search: $search) {
      cod_procedimento
      ds_procedimento
      tag_populars {
        id
        tag
      }
    }
  }
`;

export const RESULTADO_BUSCA = gql`
  query getResultado(
    $tipo: TipoBusca!
    $qr_estado: String!
    $qr_bairro: String
    $qr_especialidade: String
    $qr_exame: String
    $qr_plano_id: String
    $qr_ponto_atendimento_id: String
    $qr_profissional_id: String
    $first: Int = 15
    $page: Int = 1
  ) {
    empresarial_busca(
      tipo: $tipo
      estado: $qr_estado
      bairro: $qr_bairro
      especialidade: $qr_especialidade
      exame: $qr_exame
      plano_id: $qr_plano_id
      profissional_id: $qr_profissional_id
      ponto_atendimento_id: $qr_ponto_atendimento_id
      first: $first
      page: $page
    ) {
      paginatorInfo {
        count
        hasMorePages
        total
      }
      data {
        id
        sem_marcacao
        procedimento {
          cod_procedimento
          ds_procedimento
        }
        valor_manual
        vl_net
        preco {
          id
          valor
          produto_venda {
            id
            plano {
              id
              plano
              ds_plano
              custom_name
            }
          }
        }
        especialidade {
          id
          ds_especialidade
        }
        profissional {
          id
          user {
            nome_completo
            documentos {
              nr_documento
            }
          }
          documentos {
            nr_documento
            tipo {
              nm_tipo
            }
            conselho {
              conselho
              descricao
            }
          }
          especialidades {
            id
            ds_especialidade
          }
          area_atuacao {
            id
            area
          }
          cbos {
            codigo
            ocupacao
          }
        }
        ponto_atendimento {
          endereco {
            id
            logradouro
            endereco
            endereco
            bairro
            cep
            complemento
            latitude
            longitude
            cidade {
              nm_cidade
            }
          }
        }
        empresa {
          id
          nm_fantasia
          matriz {
            id
          }
        }
      }
    }
  }
`;

export const BUSCAR_CEP = gql`
  query buscarcep($nr_cep: String!) {
    empresarial_buscarcep(nr_cep: $nr_cep) {
      id
      altitude
      te_bairro
      nr_cep
      latitude
      longitude
      te_logradouro
      cidade {
        id
        nm_cidade
        sg_cidade
      }
    }
  }
`;

export const LISTAR_PEDIDOS = gql`
  query {
    empresarial_listar_pedidos {
      id
      descricao
      status_pedido {
        id
        status
        descricao
      }
      itempedidos {
        id
        agendamento {
          servico {
            profissional {
              id
            }
            procedimento {
              id
            }
          }
        }
      }
      pagamento {
        parcelas
        valor_parcelado
        metodo {
          metodo
        }
      }
    }
  }
`;

export const LISTAR_EMPRESAS = gql`
  query {
    empresarial_select_corretoras {
      empresa {
        id
        nm_fantasia
        logo
        documento {
          nr_documento
        }
      }
    }
  }
`;

export const LISTAR_EMPRESA_ATIVA = gql`
  query getEmpresa($id: ID!) {
    empresarial_empresa(id: $id) {
      id
      nm_fantasia
      razao_social
      inscricao_estadual
      inscricao_inss
      cnes
      logo
      importacao_custom
      contato_administrativo
      contato_financeiro
      email_empresa
      hash_integracao
      documento {
        id
        nr_documento
        tipo {
          id
          nm_tipo
        }
      }
      endereco {
        id
        logradouro
        endereco
        numero
        bairro
        cep
        complemento
        cidade {
          id
          nm_cidade
          sg_cidade
          ds_estado
          sg_estado
        }
      }
    }
  }
`;

export const ATUALIZAR_CONTATO_EMPRESA = gql`
  mutation updateContatos(
    $id: ID!
    $contato_administrativo: String
    $email_empresa: String
  ) {
    empresarial_updateContatos(
      id: $id
      contato_administrativo: $contato_administrativo
      email_empresa: $email_empresa
    ) {
      id
    }
  }
`;

export const ATUALIZAR_ENDERECO_EMPRESA = gql`
  mutation updateEndereco(
    $id: ID!
    $logradouro: String!
    $endereco: String!
    $numero: String!
    $bairro: String!
    $cep: String!
    $complemento: String
    $cidade_id: String!
  ) {
    empresarial_updateEnderecoEmpresa(
      id: $id
      logradouro: $logradouro
      endereco: $endereco
      numero: $numero
      bairro: $bairro
      cep: $cep
      complemento: $complemento
      cidade_id: $cidade_id
    ) {
      id
    }
  }
`;

export const ATUALIZAR_LOGO_EMPRESA = gql`
  mutation updateLogoEmpresa($id: ID!, $logo: String!) {
    empresarial_updateLogoEmpresa(id: $id, logo: $logo) {
      id
    }
  }
`;

export const ATUALIZAR_NOME_EMPRESA = gql`
  mutation updateNomeEmpresa(
    $id: ID!
    $nm_fantasia: String!
    $razao_social: String!
  ) {
    empresarial_updateNomeEmpresa(
      id: $id
      nm_fantasia: $nm_fantasia
      razao_social: $razao_social
    ) {
      id
    }
  }
`;

export const ATUALIZAR_DOC_EMPRESA = gql`
  mutation updateDocumentosEmpresa(
    $id: ID!
    $inscricao_estadual: String
    $inscricao_inss: String
  ) {
    empresarial_updateDocumentosEmpresa(
      id: $id
      inscricao_estadual: $inscricao_estadual
      inscricao_inss: $inscricao_inss
    ) {
      id
    }
  }
`;

export const ATUALIZAR_DADOS_REPRESENTANTE = gql`
  mutation updateDadosRepresentante(
    $id: ID!
    $email: String!
    $contato_cargo: String!
  ) {
    empresarial_updateDadosRepresentante(
      id: $id
      email: $email
      contato_cargo: $contato_cargo
    ) {
      id
    }
  }
`;

export const GET_CARGO_REPRESENTANTE = gql`
  query getCargoRepresentante($user_id: ID!, $empresa_id: ID!) {
    empresarial_cargo(user_id: $user_id, empresa_id: $empresa_id) {
      id
      email
      contato_cargo
      cargo {
        cargo
      }
      empresa {
        id
      }
      user {
        id
        documentos {
          id
          nr_documento
          tipo {
            id
            nm_tipo
          }
        }
        nome_completo
        foto
      }
    }
  }
`;

export const GET_COLABORADORES = gql`
  query getColaboradores(
    $subcontrato_id: ID
    $first: Int = 15
    $page: Int = 1
    $search: String
    $empresa_id: ID
    $dataInicio: String
    $dataFim: String
    $status: String
    $subcontratos: String
    $consultor: String
  ) {
    empresarial_grafico_colaboradores(
      subcontrato_id: $subcontrato_id
      search: $search
      empresa_id: $empresa_id
      dataInicio: $dataInicio
      dataFim: $dataFim
      status: $status
      consultor: $consultor
      subcontrato: $subcontratos
    ) {
      total_colaboradores
      total_dependentes
    }
    filtrar_colaboradores(
      subcontrato_id: $subcontrato_id
      search: $search
      empresa_id: $empresa_id
      dataInicio: $dataInicio
      consultor: $consultor
      dataFim: $dataFim
      status: $status
      subcontrato: $subcontratos
      first: $first
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        total
      }
      data {
        id
        matricula
        consultor_cpf
        user {
          id
          nome_completo
          celular
          documentos {
            id
            nr_documento
            tipo {
              id
              nm_tipo
            }
          }
          dt_nascimento
        }
        subcontrato {
          id
          plano {
            plano
          }
        }
        subempresa {
          subcontrato {
            id
            plano {
              plano
            }
          }
        }
        created_at
      }
    }
  }
`;

export const GET_DETALHE_COLABORADOR = gql`
  query getColaborador($id: ID!) {
    empresarial_colaborador(id: $id) {
      id
      user {
        id
        nome_completo
        celular
        email
        sexo
        documentos {
          id
          nr_documento
          tipo {
            id
            nm_tipo
          }
        }
        dt_nascimento
      }
      subcontrato {
        id
        plano {
          plano
        }
      }
      subempresa {
        subcontrato {
          id
          plano {
            plano
          }
        }
      }
      created_at
    }
    relacoes {
      id
      relacao
    }
  }
`;

export const GET_DEPENDENTES_COLABORADOR = gql`
  query getDependentes($id: ID!) {
    empresarial_dependentes(colaborador_id: $id) {
      id
      relacao {
        id
        relacao
      }
      user {
        id
        nome_completo
        celular
        dt_nascimento
        sexo
        email
        documentos {
          id
          nr_documento
          tipo {
            id
            nm_tipo
          }
        }
      }
    }
  }
`;

export const BUSCAR_CLIENTE_COMPRA = gql`
  query getCliente($documento: String!, $empresa_id: ID) {
    empresarial_filtrar_colaborador(
      search: $documento
      empresa_id: $empresa_id
    ) {
      id
      user {
        id
        nome_completo
        dt_nascimento
        celular
        email
        documentos {
          id
          nr_documento
          tipo {
            id
            nm_tipo
          }
        }
      }
      nivel_relacao {
        colaborador_id
        user {
          id
          nome_completo
          dt_nascimento
          celular
          email
          documentos {
            id
            nr_documento
            tipo {
              id
              nm_tipo
            }
          }
        }
      }
    }
  }
`;

export const GET_CARRINHO_ITEM = gql`
  query getCarrinho($key: String!) {
    empresarial_items_carrinho(key: $key) {
      id
      paciente {
        id
        nome_completo
      }
      valor
      dt_agendamento
      turno
      servico {
        profissional {
          user {
            nome_completo
          }
        }
        ponto_atendimento {
          endereco {
            id
            logradouro
            endereco
            endereco
            bairro
            cep
            complemento
            latitude
            longitude
            cidade {
              nm_cidade
            }
          }
        }
        especialidade {
          ds_especialidade
        }
        empresa {
          nm_fantasia
        }
        procedimento {
          ds_procedimento
        }
      }
    }
  }
`;

export const GET_MOVIMENTACAO = gql`
  query getMovitacao($empresa_id: String!) {
    empresarial_movimentacao(empresa_id: $empresa_id) {
      id
      tp_movimento
      status_mv
      dt_ativacao
      valor
      descricao
      empresa {
        nm_fantasia
      }
    }
  }
`;

export const GET_SALDO_EMPRESARIAL = gql`
  query getSaldo($empresa_id: ID!) {
    empresarial_saldo_empresarial(empresa_id: $empresa_id) {
      debito
      credito
      disponivel
    }
  }
`;

export const GET_RESULT_PEDIDO = gql`
  query getDetalhePedido($pedido_id: ID!) {
    empresarial_detalhePedido(id: $pedido_id) {
      id
      descricao
      itempedidos {
        id
        valor
        turno
        agendamento {
          id
          dt_agendamento
          paciente {
            id
            nome_completo
          }
          servico {
            profissional {
              user {
                nome_completo
              }
            }
            especialidade {
              ds_especialidade
            }
            procedimento {
              ds_procedimento
            }
            empresa {
              nm_fantasia
            }
            ponto_atendimento {
              endereco {
                logradouro
                endereco
                numero
                bairro
                cep
                complemento
                latitude
                longitude
                cidade {
                  nm_cidade
                  sg_cidade
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const REMOVER_COLABORADOR = gql`
  mutation removerColaborador($id: ID!) {
    empresarial_removerColaboradorEmpresa(id: $id) {
      id
    }
  }
`;

export const REMOVER_DEPENDENTE = gql`
  mutation removerDependente($id: ID!) {
    empresarial_removerDependenteColaborador(id: $id) {
      id
    }
  }
`;

export const GET_EXTRATO_UTILIZACAO = gql`
  query getExtrato($colaborador_id: ID!) {
    empresarial_extratoUtilizacao(colaborador_id: $colaborador_id) {
      id
      dt_agendamento
      colaborador_id
      paciente {
        id
        nome_completo
      }
      item_pedido {
        valor
      }
      status {
        id
        status
      }
    }
  }
`;

export const UPDATE_COLABORADOR = gql`
  mutation updateColaborador(
    $id: ID!
    $nome_completo: String!
    $celular: String!
    $dt_nascimento: String!
    $email: String!
    $sexo: String!
  ) {
    empresarial_updateColaborador(
      id: $id
      nome_completo: $nome_completo
      celular: $celular
      dt_nascimento: $dt_nascimento
      email: $email
      sexo: $sexo
    ) {
      id
    }
  }
`;

export const LISTAR_COLABORADORES = gql`
  query getColaboradores($empresa_id: ID!, $filtro: String) {
    empresarial_usuarios(empresa_id: $empresa_id, filtro: $filtro) {
      id
      email
      contato_cargo
      cargo {
        cargo
      }
      user {
        id
        nome_completo
        celular
        telefone
        dt_nascimento
        sexo
        documentos {
          nr_documento
        }
      }
    }
  }
`;

export const CARGOS_GRAPHQL = gql`
  query {
    empresarial_cargoEmpresa {
      id
      cargo
      ds_cargo
    }
  }
`;

export const GET_USUARIO = gql`
  query getCargoRepresentante($user_id: ID!, $empresa_id: ID!) {
    empresarial_usuario(user_id: $user_id, empresa_id: $empresa_id) {
      id
      email
      contato_cargo
      cargo {
        cargo
      }
      user {
        id
        nome_completo
        celular
        telefone
        dt_nascimento
        sexo
        documentos {
          nr_documento
        }
      }
    }
  }
`;

export const GET_CARGOS_USUARIO = gql`
  query getCargosUsuario($user_id: ID!, $empresa_id: ID!) {
    empresarial_cargosUsuario(user_id: $user_id, empresa_id: $empresa_id) {
      id
      email
      contato_cargo
      cargo {
        id
        cargo
        ds_cargo
      }
    }
  }
`;

export const GET_PLANOS_USER_SELECTED = gql`
  query getPlanos($empresa_id: ID!, $user_id: ID!) {
    empresarial_filtrar_plano_colaborador(
      empresa_id: $empresa_id
      user_id: $user_id
    ) {
      id
      subcontrato {
        id
        plano {
          id
          plano
        }
        versao_contrato {
          id
          contrato {
            id
          }
        }
      }
    }
  }
`;

export const GET_MOVIMENTACAO_EMPRESA = gql`
  query getMovimentacao($empresa_id: ID!) {
    empresarial_agrupador(empresa_id: $empresa_id) {
      ativos
      deletados
      processados
      agendado
      user {
        nome_completo
        documentos {
          nr_documento
          tipo {
            nm_tipo
          }
        }
      }

      subcontrato {
        id
        plano {
          id
          plano
        }
        versao_contrato {
          id
          contrato {
            descricao
            id
          }
        }
      }
      created_at
    }
  }
`;

export const GET_DADOS_MOVIMENTACAO_EMPRESA = gql`
  query getMovimentacaoDados(
    $subcontrato_id: ID!
    $created_at: String!
    $page: Int!
    $first: Int!
  ) {
    listar_relacao_importados(
      subcontrato_id: $subcontrato_id
      created_at: $created_at
      page: $page
      first: $first
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        total
      }
      data {
        id
        nome_completo
        dt_nascimento
        documento
        doc_responsavel
        email
        telefone
        sexo
        celular
        dt_inclusao
        movimento

        incluido
        removido

        updated_at
        nr_proposta
        dt_fim
        qnt_dia

        check
        error
      }
    }
  }
`;

export const GET_PRESTADORES_RESULTADO = gql`
  query getPrestadores(
    $tipo: TipoBusca!
    $qr_estado: String!
    $qr_bairro: String
    $qr_especialidade: String
    $qr_exame: String
  ) {
    empresarial_buscar_prestadores(
      tipo: $tipo
      estado: $qr_estado
      bairro: $qr_bairro
      especialidade: $qr_especialidade
      exame: $qr_exame
    ) {
      id
      descricao
    }
  }
`;

export const GET_PROFISSIONAL_PRESTADOR = gql`
  query getProfissionais(
    $tipo: TipoBuscaConsulta!
    $ponto_atendimento_id: String!
    $especialidade_id: String!
  ) {
    empresarial_buscar_profissionais(
      tipo: $tipo
      ponto_atendimento_id: $ponto_atendimento_id
      especialidade_id: $especialidade_id
    ) {
      id
      user {
        nome_completo
      }
    }
  }
`;

export const AGENDAMENT_AGRUPADO = gql`
  query getAgendamentoAgrupado(
    $filtro: String
    $periodo: String
    $ano: String
    $empresa_id: String
    $page: Int!
    $first: Int!
  ) {
    empresarial_agendamento_agrupado(
      filtro: $filtro
      periodo: $periodo
      ano: $ano
      empresa_id: $empresa_id
      page: $page
      first: $first
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        total
      }
      data {
        id
        descricao
        created_at
        user {
          id
          nome_completo
        }

        status_pedido {
          id
          status
          descricao
        }
        pagamento {
          total
          atualizacao_pagamento {
            id
            baixa {
              descricao
            }
            created_at
          }
        }
        representante {
          id
          empresa {
            nm_fantasia
          }
        }
        itempedidos {
          id
          valor
          pedido {
            id
            pagamento {
              metodo {
                id
                metodo
              }
            }
          }
          agendamento {
            id
            ticket
            dt_agendamento
            colaborador {
              subcontrato {
                plano {
                  plano
                }
              }
            }
            paciente {
              id
              nome_completo
              carrinho {
                id
              }
              documentos {
                nr_documento
                tipo {
                  id
                  nm_tipo
                }
              }
            }
            servico {
              id
              profissional {
                id
                user {
                  id
                  nome_completo
                }
              }
              especialidade {
                id
                ds_especialidade
              }
              procedimento {
                id
                cod_procedimento
                ds_procedimento
              }
              ponto_atendimento {
                cnpj_utilizadora
                endereco {
                  logradouro
                  endereco
                  numero
                  bairro
                  cep
                  cidade {
                    id
                    nm_cidade
                    sg_cidade
                  }
                }
              }
              empresa {
                id
                nm_fantasia
              }
            }
            status {
              id
              status
              codigo
            }
          }
        }
      }
    }
  }
`;

export const CLIENTES_EMPRESA_RELATORIO = gql`
  query getClienteRelatorio(
    $empresa_id: ID!
    $page: Int!
    $first: Int!
    $dataInicio: String
    $dataFim: String
  ) {
    empresarial_buscar_clientes_relatorio(
      empresa_id: $empresa_id
      dt_inicio: $dataInicio
      dt_fim: $dataFim
      page: $page
      first: $first
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        total
      }
      data {
        id
        created_at
        deleted_at
        subcontrato {
          plano {
            plano
          }
        }
        user {
          nome_completo
          celular
          dt_nascimento
          email
          documentos {
            nr_documento
          }
        }
        nivel_relacao {
          user {
            nome_completo
            celular
            dt_nascimento
            email
          }
          created_at
          deleted_at
        }
      }
    }
  }
`;

export const LISTAR_COLABORADORES_EXCEL = gql`
  query getColaboradoresExcel(
    $empresa_id: ID
    $first: Int = 30
    $page: Int = 1
    $search: String
    $dataInicio: String
    $dataFim: String
    $status: String
    $subcontratos: String
    $consultor: String
  ) {
    empresarial_listar_colaboradores(
      empresa_id: $empresa_id
      first: $first
      consultor: $consultor
      page: $page
      search: $search
      dataInicio: $dataInicio
      dataFim: $dataFim
      status: $status
      subcontrato: $subcontratos
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        total
      }
      data {
        id
        matricula
        created_at
        deleted_at
        consultor_cpf
        user {
          nome_completo
          dt_nascimento
          sexo
          celular
          email
          documentos {
            nr_documento
            tipo {
              nm_tipo
            }
          }
        }
        subcontrato {
          plano {
            plano
          }
          versao_contrato {
            contrato {
              descricao
              empresa {
                nm_fantasia
              }
            }
          }
        }
        nivel_relacao {
          id
          created_at
          relacao {
            relacao
          }
          user {
            id
            nome_completo
            dt_nascimento
            sexo
            celular
            email
            created_at
            documentos {
              nr_documento
              tipo {
                nm_tipo
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CADASTRO_PENDENTES = gql`
  query getClienteRelatorio(
    $empresa_id: ID!
    $filtro: String!
    $page: Int!
    $first: Int!
  ) {
    empresarial_buscar_solicitacoes(
      empresa_id: $empresa_id
      filtro: $filtro
      page: $page
      first: $first
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        total
      }
      data {
        id
        nome_completo
        dt_nascimento
        sexo
        nr_documento
        nr_reserva
        email
        telefone
        celular
        data_inicio
        data_fim
        duracao
        situacao
        subcontrato_id
        colaborador_id
        colaborador {
          user {
            nome_completo
            documentos {
              nr_documento
              tipo {
                nm_tipo
              }
            }
          }
        }
        subcontrato {
          id
          plano {
            plano
          }
        }
      }
    }
  }
`;

export const ATUALIZAR_HASH_INTEGRACAO = gql`
  mutation updateHashIntegracao($id: ID!) {
    empresarial_updateHashIntegracao(id: $id)
  }
`;

export const REMOVER_CADASTRO_PENDENTE = gql`
  mutation removerSolicitacaoPendente($id: ID!) {
    empresarial_removerSolicitacaoPendente(id: $id) {
      id
    }
  }
`;

export const REMOVER_IMPORTACAO_PENDENTE = gql`
  mutation removerImportacaoPendente($id: ID!) {
    empresarial_removerImportacaoPendente(id: $id) {
      id
    }
  }
`;

export const CADASTRAR_CORRETORA = gql`
  mutation cadastrarCorretora($data: PayloadEmpresa!) {
    empresarial_cadastrarCorretora(data: $data) {
      id
    }
  }
`;

export const BUSCAR_CORRETORES = gql`
  query getCorretores($page: Int!, $first: Int!, $equipe_venda_id: ID!) {
    empresarial_getCorretores(
      page: $page
      first: $first
      equipe_venda_id: $equipe_venda_id
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        total
      }
      data {
        id
        email
        contato_cargo
        cargo {
          cargo
        }
        empresa {
          id
          nm_fantasia
        }
        user {
          id
          documentos {
            id
            nr_documento
            tipo {
              id
              nm_tipo
            }
          }
          nome_completo
        }
        created_at
      }
    }
  }
`;

export const BUSCAR_DADOS_CORRETOR = gql`
  query getCorretorDetalhe($id: ID!, $equipe_venda_id: ID!) {
    empresarial_getCorretorDetalhe(id: $id, equipe_venda_id: $equipe_venda_id) {
      id
      email
      contato_cargo
      cargo {
        cargo
      }
      empresa {
        id
      }
      user {
        id
        documentos {
          id
          nr_documento
          tipo {
            id
            nm_tipo
          }
        }
        dt_nascimento
        nome_completo
        sexo
      }
    }
  }
`;

export const CADASTRAR_CORRETOR = gql`
  mutation cadastrarCorretor(
    $empresa_id: ID!
    $equipe_id: ID!
    $data_user: PayloadCorretorEmpresa!
  ) {
    empresarial_cadastrarCorretor(
      empresa_id: $empresa_id
      equipe_id: $equipe_id
      data_user: $data_user
    ) {
      id
    }
  }
`;

export const ATUALIZAR_CORRETOR = gql`
  mutation atualizarCorretor(
    $corretor_id: ID!
    $data_user: PayloadAtualizarCorretorEmpresa!
  ) {
    empresarial_atualizarCorretor(
      corretor_id: $corretor_id
      data_user: $data_user
    ) {
      id
    }
  }
`;

export const REMOVER_EQUIPE = gql`
  mutation removerEquipe($id: ID!) {
    empresarial_removerEquipe(id: $id) {
      id
    }
  }
`;

export const REMOVER_CORRETOR = gql`
  mutation removerCorretor($id: ID!) {
    empresarial_removerCorretor(id: $id) {
      id
    }
  }
`;

export const BUSCAR_EQUIPES = gql`
  query getEquipes(
    $page: Int!
    $first: Int!
    $empresa_id: ID!
    $filtro: String
  ) {
    empresarial_getEquipes(
      page: $page
      first: $first
      empresa_id: $empresa_id
      filtro: $filtro
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        total
      }
      data {
        id
        ds_equipe
        nm_gestor
        celular
        email
        documento
        created_at
        updated_at
      }
    }
  }
`;

export const CADASTRAR_EQUIPE = gql`
  mutation cadastrarEquipe(
    $empresa_id: ID!
    $ds_equipe: String!
    $nm_gestor: String!
    $celular: String!
    $email: String!
    $documento: String!
  ) {
    empresarial_cadastrarEquipe(
      empresa_id: $empresa_id
      ds_equipe: $ds_equipe
      nm_gestor: $nm_gestor
      celular: $celular
      email: $email
      documento: $documento
    ) {
      id
    }
  }
`;

export const EDITAR_EQUIPE = gql`
  mutation editarEquipe(
    $id: ID!
    $empresa_id: ID!
    $ds_equipe: String!
    $nm_gestor: String!
    $celular: String!
    $email: String!
    $documento: String!
  ) {
    empresarial_editarEquipe(
      id: $id
      empresa_id: $empresa_id
      ds_equipe: $ds_equipe
      nm_gestor: $nm_gestor
      celular: $celular
      email: $email
      documento: $documento
    ) {
      id
    }
  }
`;

export const LISTAR_LINKS_VENDA = gql`
  query getLinksVenda(
    $page: Int!
    $first: Int!
    $empresas: String
    $user_id: ID
  ) {
    empresarial_getEmpresasLinks(user_id: $user_id) {
      id
      nm_fantasia
    }
    empresarial_getLinks(page: $page, first: $first, empresas: $empresas) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        total
      }
      data {
        id
        link_adesao
        plano {
          plano
        }
        metodo_cartao
        recorrencia_cartao
        metodo_boleto
        recorrencia_boleto
        valores {
          valor_boleto
          valor_cartao
          reco_cartao
          reco_boleto
        }
        versao_contrato {
          contrato {
            descricao
            empresa {
              nm_fantasia
            }
          }
        }
      }
    }
  }
`;

export const LISTAR_LINKS_VENDA_USER_LOGADO = gql`
  query getLinksVenda($empresa_id: ID, $user_id: ID) {
    empresarial_getUserEquipes(user_id: $user_id, empresa_id: $empresa_id) {
      id
      empresa {
        nm_fantasia
      }
      equipe_vendas {
        ds_equipe
      }
    }
  }
`;

export const USER_LOGGED = gql`
  query {
    empresarial_me {
      id
      nome_completo
      celular
      sexo
      email
      telefone
      created_at
      dt_nascimento
      documentos {
        id
        nr_documento
        tipo {
          nm_tipo
        }
      }
    }
  }
`;

export const ACOMPANHAR_VENDAS = gql`
  query getAcompVendas(
    $page: Int!
    $first: Int!
    $empresa_id: ID
    $filtro: String
    $data_inicio: String
    $data_fim: String
  ) {
    empresarial_getAcompVendas(
      page: $page
      first: $first
      empresa_id: $empresa_id
      filtro: $filtro
      data_inicio: $data_inicio
      data_fim: $data_fim
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        total
      }
      data {
        id
        total
        created_at
        metodo {
          id
          metodo
        }
        pedido {
          id
          user {
            nome_completo
            documentos {
              nr_documento
              tipo {
                nm_tipo
                ds_tipo
              }
            }
          }
          itempedido {
            id
            assinatura {
              id
              suspenso
              subcontrato {
                id
                plano {
                  plano
                }
              }
              versao {
                totalAssinantes
              }
            }
          }
          itempedidos {
            assinatura {
              versoes {
                id
              }
            }
          }
        }
        corretor {
          id
          nome_completo
        }
        corretor_equipe {
          user {
            nome_completo
          }
          equipe_vendas {
            ds_equipe
          }
        }
        atualizacao_pagamento {
          id
          cod_assinatura
          cod_cobranca
          cod_adquirente
          message
          status
          created_at
        }
      }
    }
  }
`;

export const HISTORICO_FATURA_ASSINATURA = gql`
  query historicoFaturaAssinatura($pedido_id: ID!) {
    empresarial_historicoFaturaAssinatura(pedido_id: $pedido_id) {
      id
      cod_assinatura
      cod_cobranca
      status
      created_at
    }
  }
`;

export const OBTER_BENEFICIARIOS = gql`
  query getBeneficiarios($versao_assinatura_id: ID!) {
    empresarial_beneficiarios_assinatura(
      versao_assinatura_id: $versao_assinatura_id
    ) {
      id
      created_at
      user {
        nome_completo
        dt_nascimento
        celular
        email
        documentos {
          id
          nr_documento
          tipo {
            id
            nm_tipo
          }
        }
      }
    }
  }
`;

export const BUSCAR_TODOS_CORRETORES = gql`
  query getTodosCorretores(
    $page: Int!
    $first: Int!
    $empresa_id: ID!
    $filtro: String
  ) {
    empresarial_getTodosCorretores(
      page: $page
      first: $first
      empresa_id: $empresa_id
      filtro: $filtro
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        total
      }
      data {
        id
        created_at
        email
        contato_cargo
        cargo {
          cargo
        }
        empresa {
          id
          nm_fantasia
        }
        equipe_vendas {
          id
          ds_equipe
          nm_gestor
          documento
          celular
          email
        }
        user {
          id
          documentos {
            id
            nr_documento
            tipo {
              id
              nm_tipo
            }
          }
          nome_completo
        }
      }
    }
  }
`;

export const GET_EQUIPES_CORRETOR = gql`
  query getEquipesCorretor {
    empresarial_getEquipesCorretor {
      id
      email
      contato_cargo
      cargo {
        cargo
      }
      empresa {
        id
        nm_fantasia
        razao_social
        inscricao_estadual
        inscricao_inss
        cnes
        logo
        importacao_custom
        contato_administrativo
        contato_financeiro
        email_empresa
        hash_integracao
        documento {
          id
          nr_documento
          tipo {
            id
            nm_tipo
          }
        }
        endereco {
          id
          logradouro
          endereco
          numero
          bairro
          cep
          complemento
          cidade {
            id
            nm_cidade
            sg_cidade
            ds_estado
            sg_estado
          }
        }
      }
      equipe_vendas {
        id
        ds_equipe
      }
      user {
        id
        documentos {
          id
          nr_documento
          tipo {
            id
            nm_tipo
          }
        }
        nome_completo
        foto
      }
    }
  }
`;

export const GET_CORRETORAS = gql`
  query getCorretoras($filtro: String) {
    getCorretoras(filtro: $filtro) {
      id
      nm_fantasia
    }
  }
`;

export const GET_EQUIPES = gql`
  query getEquipes($corretora: ID!) {
    empresarial_getEquipes(corretora: $corretora) {
      id
      ds_equipe
    }
  }
`;

export const VENDAS_EMPRESARIAL = gql`
  mutation vendaEmpresarial($data: VendasEmpresarial) {
    empresarial_vendaEmpresarial(data: $data)
  }
`;
