/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useRef } from 'react';
import { useToasts } from 'react-toast-notifications';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import useAuth from '~/config/auth_provider/contexto';
import { LISTAR_LINKS_VENDA } from '~/config/gql';
import Header from '../../layouts/header';
import { columns, paginationOptions, customStyles } from '../constantes';
import { ColaboradoresTabela, Content, CustomStyleDataTable } from '../styles';

export default function LinksVenda() {
  const { content } = useParams();
  let parse = {};
  if (content !== undefined) {
    const parsed = atob?.(content);
    parse = JSON?.parse?.(parsed);
  }
  const page = useRef(1);
  const { addToast } = useToasts();
  const { auth_object: auth, loading_me, empresa_ativa } = useAuth();
  const [getLinks, { loading, data }] = useLazyQuery(LISTAR_LINKS_VENDA, {
    context: {
      headers: {
        Authorization: auth.access_token,
      },
    },
    fetchPolicy: 'no-cache',
    skip: !auth.access_token || !empresa_ativa.id,
  });

  useEffect(() => {
    if (empresa_ativa.id) {
      getLinks({
        variables: {
          page: page.current,
          first: 15,
          empresas: empresa_ativa.id,
        },
      });
    }
  }, [empresa_ativa]);

  const onChagePage = (pg) => {
    page.current = pg;
    getLinks({
      variables: {
        first: 15,
        page: page.current,
        empresas: empresa_ativa.id,
      },
    });
  };

  return (
    <Content>
      <Header
        title={
          parse?.user?.nome_completo &&
          `Links de venda - ${parse?.user?.nome_completo}`
        }
        parse
        texto=""
      />

      <ColaboradoresTabela>
        <CustomStyleDataTable>
          {/* <LoadingContent loading={loading}> */}
          <DataTable
            noHeader
            pagination
            paginationComponentOptions={paginationOptions}
            noDataComponent="Nenhum link encontrado"
            columns={columns({
              addToast,
              userid: parse?.user?.id,
              corretor_id: parse?.id,
              nome_completo: parse?.user?.nome_completo || '-',
              ds_equipe: parse?.equipe?.ds_equipe,
              nm_empresa: parse?.empresa?.nm_fantasia,
            })}
            data={data?.empresarial_getLinks?.data || []}
            customStyles={customStyles}
            dense
            paginationServer
            progressPending={loading || loading_me}
            paginationTotalRows={
              data?.empresarial_getLinks?.paginatorInfo?.total || 0
            }
            onChangePage={onChagePage}
            paginationPerPage={15}
          />
          {/* </LoadingContent> */}
        </CustomStyleDataTable>
      </ColaboradoresTabela>
    </Content>
  );
}
