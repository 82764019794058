/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import ButtonRound from '~/components/button_round';
import CustomInputMask from '~/components/mask_input';
import InputForm from '~/components/input_form';
import { CadastroCorretorRequest } from '~/modules/auth/actions';
import { checkIsCelular } from '~/config/util';
import { checkIsCPF } from '~/config/utils';
import { uriCliente } from '~/config/api_apollo';
import { GET_CORRETORAS, GET_EQUIPES } from '~/config/gql';
import IfComponent from '~/components/if_component';
import Termos from '~/components/termos';
import { TermoInput } from './styles';
import { BackInt } from '../styles';

export default function VincularCorretora({ recaptchaRef, setCadastro }) {
  const dispatch = useDispatch();
  const [showTermo, setShowTermo] = useState(false);
  const { register, control, handleSubmit, errors, watch } = useForm({
    defaultValues: {},
  });
  const state = useSelector((st) => st);
  const items = watch();
  const [getCorretoras, { data, loading }] = useLazyQuery(GET_CORRETORAS, {
    context: {
      uri: uriCliente[process.env.NODE_ENV],
    },
    fetchPolicy: 'no-cache',
  });

  const [getEquipes, { data: dataEquipes, loadingEquies }] = useLazyQuery(
    GET_EQUIPES,
    {
      context: {
        uri: uriCliente[process.env.NODE_ENV],
      },
      fetchPolicy: 'no-cache',
      skip: items.corretora === 'Selecionar',
    }
  );

  useEffect(() => {
    getCorretoras();
  }, []);

  const getText = (name) => {
    return name.nm_fantasia;
  };
  return (
    <form
      id="vincular-form"
      onSubmit={handleSubmit(async (dataItem) => {
        const token = await recaptchaRef.current.executeAsync();
        const callback = () => {};
        dispatch(
          CadastroCorretorRequest({
            ...dataItem,
            type: 'VINCULAR',
            token,
            callback,
          })
        );
        // eslint-disable-next-line no-unused-expressions
        recaptchaRef.current?.reset();
      })}
      className="text-center"
    >
      <BackInt onClick={() => setCadastro('caminho')}>
        <i className="far fa-chevron-left" /> Voltar
      </BackInt>
      <h4 className="title">Realizar vinculo</h4>

      <InputForm
        labelName="Nome Completo"
        required
        validacao={errors?.nome_completo}
        mensagemDeErro="Informe seu nome completo"
      >
        <input
          type="text"
          maxLength={100}
          name="nome_completo"
          className="form-control"
          placeholder="Nome Completo"
          ref={register({ required: true })}
        />
      </InputForm>
      <InputForm
        labelName="CPF"
        required
        validacao={errors?.documento}
        mensagemDeErro="Informe seu CPF"
      >
        <Controller
          as={<CustomInputMask />}
          onChange={([e]) => ({ value: e })}
          rules={{
            validate: {
              inputCPFRequired: checkIsCPF,
            },
          }}
          name="documento"
          mask="999.999.999-99"
          maskChar="_"
          placeholder="___.___.___-__"
          className="form-control"
          control={control}
        />
      </InputForm>
      <InputForm
        labelName="Data de nascimento"
        required
        validacao={errors?.dt_nascimento}
        mensagemDeErro="Informe sua data de nascimento"
      >
        <input
          type="date"
          name="dt_nascimento"
          className="form-control"
          placeholder="Informe a Data de Nascimento"
          ref={register({ required: true })}
        />
      </InputForm>
      <InputForm
        labelName="Sexo"
        required
        validacao={errors?.sexo}
        mensagemDeErro="Selecione o sexo"
      >
        <select
          className="form-control"
          name="sexo"
          ref={register({
            required: true,
            validate: {
              inputCelularRequired: (e) => {
                return e === 'Selecionar'
                  ? 'Selecione uma corretora'
                  : undefined;
              },
            },
          })}
        >
          <option>Selecionar</option>
          <option value="M">Masculino</option>
          <option value="F">Feminino</option>
        </select>
      </InputForm>
      <InputForm
        labelName="Email"
        required
        validacao={errors?.email}
        mensagemDeErro="Informe seu email"
      >
        <input
          type="email"
          name="email"
          className="form-control"
          maxLength={100}
          placeholder="exemplo@gmail.com"
          ref={register({ required: true })}
        />
      </InputForm>
      <InputForm
        labelName="Celular"
        required
        validacao={errors?.celular}
        mensagemDeErro="Informe seu celular"
      >
        <Controller
          as={<CustomInputMask />}
          onChange={([e]) => ({ value: e })}
          rules={{
            validate: {
              inputCelularRequired: checkIsCelular,
            },
          }}
          name="celular"
          mask="(99) 9 9999-9999"
          maskChar="_"
          placeholder="(00) 9 0000-0000"
          className="form-control"
          control={control}
        />
      </InputForm>
      <InputForm
        labelName="Senha"
        required
        validacao={errors?.password}
        mensagemDeErro="Informe sua senha"
      >
        <input
          type="password"
          name="password"
          className="form-control"
          maxLength={10}
          ref={register({ required: true })}
        />
      </InputForm>

      <InputForm
        labelName="Corretora"
        required
        validacao={errors?.corretora}
        mensagemDeErro="Selecione uma corretora"
      >
        <select
          className="form-control"
          name="corretora"
          disabled={loading}
          onChange={(e) => {
            if (e?.target?.value !== 'Selecionar') {
              getEquipes({
                variables: {
                  corretora: e?.target?.value,
                },
              });
            }
          }}
          ref={register({
            required: true,
            validate: {
              inputCelularRequired: (e) => {
                return e === 'Selecionar'
                  ? 'Selecione uma corretora'
                  : undefined;
              },
            },
          })}
        >
          <option value={undefined}>Selecionar</option>
          {data?.empresarial_getCorretoras
            ?.filter(
              (corretora) => corretora.id === '949' || corretora.id === 949
            )
            .map((item) => (
              <option value={item.id}>{getText(item)}</option>
            ))}
          {data?.empresarial_getCorretoras
            ?.filter(
              (corretora) => !(corretora.id === '949' || corretora.id === 949)
            )
            .map((item) => (
              <option value={item.id}>{getText(item)}</option>
            ))}
        </select>
      </InputForm>

      <IfComponent hide={items.corretora === 'Selecionar'}>
        <InputForm
          labelName="Equipe"
          required
          validacao={errors?.equipe}
          mensagemDeErro="Selecione uma equipe"
        >
          <select
            className="form-control"
            name="equipe"
            disabled={loadingEquies}
            ref={register({
              required: true,
              validate: {
                inputCelularRequired: (e) => {
                  return e === 'Selecionar'
                    ? 'Selecione uma corretora'
                    : undefined;
                },
              },
            })}
          >
            <option value={undefined}>Selecionar</option>
            {dataEquipes?.getEquipes?.map((item) => (
              <option value={item.id}>{item.ds_equipe}</option>
            ))}
          </select>
        </InputForm>
      </IfComponent>

      <TermoInput htmlFor="termo" className="termo">
        <input
          id="termo"
          type="checkbox"
          name="termo"
          ref={register({
            required: true,
          })}
        />{' '}
        <p
          style={{
            textAlign: 'left !important',
            margin: '0',
            color: errors?.termo ? 'red' : 'none',
          }}
        >
          Declaro que li e concordo com as {'  '}
          <a href="#" onClick={() => setShowTermo(true)}>
            Condições Comerciais
          </a>{' '}
          pactuadas com a Dr Hoje.
        </p>
      </TermoInput>

      <ButtonRound type="submit" disabled={state.auth.loading}>
        finalizar
      </ButtonRound>

      <p>
        Protegido por reCAPTCHA e sujeito à{' '}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          without
          rel="noopener noreferrer"
        >
          Política de privacidade
        </a>{' '}
        e aos{' '}
        <a href="#" onClick={() => setShowTermo(true)}>
          Termos de serviço
        </a>{' '}
        da DrHoje.
        <Termos show={showTermo} onHide={() => setShowTermo(false)} />
      </p>
    </form>
  );
}
