/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import {
  ColaboradoresTabela,
  ContentGeral,
  CustomStyleDataTable,
} from './styles';
import Header from '../../layouts/header';
import Button from '~/components/button';
import CadastrarNovoCorretor from './new';
import { BUSCAR_CORRETORES, REMOVER_CORRETOR } from '~/config/gql';
import useAuth from '~/config/auth_provider/contexto';
import ExportarConsultoresExcel from './excel';
import { RequestAlertConfirm } from '~/config/alert';
import ButtonsAcaoDatatable from '~/components/buttons_acao_datatable';
import IfComponent from '~/components/if_component';

export default function Corretores() {
  const { equipe_venda_id, content } = useParams();
  const item = JSON.parse(atob(content));
  const [novaCorretor, setNovaCorretor] = useState(false);
  const page = useRef(1);
  const { auth, dispatch } = useAuth();

  const [removerCorretor] = useMutation(REMOVER_CORRETOR);

  const [
    getCorretores,
    { data, loading, refetch: listarCorretores },
  ] = useLazyQuery(BUSCAR_CORRETORES, {
    context: {
      headers: {
        Authorization: auth,
      },
    },
    fetchPolicy: 'no-cache',
    skip: !auth,
  });

  useEffect(() => {
    getCorretores({
      variables: {
        first: 15,
        page: page.current,
        equipe_venda_id,
      },
    });
  }, []);

  const onChagePage = (pg) => {
    page.current = pg;
    getCorretores({
      variables: {
        first: 15,
        page: page.current,
        equipe_venda_id,
      },
    });
  };

  const remover = (row) => {
    const check = (call) => {
      if (call) {
        removerCorretor({
          variables: { id: row?.id },
          context: {
            headers: {
              Authorization: auth,
            },
          },
          skip: !row?.id || auth,
        }).then(() => {
          listarCorretores();
        });
      }
    };

    dispatch(
      RequestAlertConfirm(
        `Você vai remover o corretor ${row?.user?.nome_completo}, deseja continuar?`,
        '',
        true,
        check
      )
    );
  };

  const ActionList = (row) => {
    const vendas = btoa(
      unescape(encodeURIComponent(JSON.stringify({ ...row, equipe: item })))
    );
    const edit = btoa(unescape(encodeURIComponent(JSON.stringify(row))));
    return (
      <ButtonsAcaoDatatable>
        <Link
          to={`/corretor/links/${row?.user?.id}/vendas/${row?.id}/${vendas}`}
        >
          <button type="button" className="edit">
            <i className="fas fa-external-link" />
          </button>
        </Link>
        <Link to={`/corretor/${equipe_venda_id}/edit/${row?.id}/${edit}`}>
          <button type="button" className="edit">
            <i className="fas fa-pencil" />
          </button>
        </Link>

        <button
          type="button"
          className="remove"
          onClick={() => {
            remover(row);
          }}
        >
          <i className="ver fas fa-trash" />
        </button>
      </ButtonsAcaoDatatable>
    );
  };

  return (
    <ContentGeral>
      <IfComponent hide={novaCorretor}>
        <Header title={`Corretores da equipe ${item.ds_equipe}`} texto="" />

        <ColaboradoresTabela>
          <CustomStyleDataTable>
            <div className="control-input">
              <Button
                load={false}
                className="novo-corretor "
                disabled={false}
                type="button"
                radius="22px"
                onClick={() => {
                  setNovaCorretor(true);
                }}
              >
                Novo Corretor
              </Button>
            </div>
            <ExportarConsultoresExcel
              auth={auth}
              hide={
                !data?.empresarial_getCorretores?.data?.empresarial_length > 0
              }
              first={15}
              payload={{ ...item }}
              colaboradorFilters={{
                equipe_venda_id,
              }}
            />

            <DataTable
              noHeader
              pagination
              noDataComponent="Nenhuma corretor encontrado, tente cadastrar uma para ser listado aqui."
              columns={[
                {
                  name: 'ID',
                  selector: 'id',
                  sortable: true,
                },
                {
                  name: 'Consultor',
                  sortable: true,
                  cell: (row) => row?.user?.nome_completo,
                },
                {
                  name: 'E-mail',
                  sortable: true,
                  cell: (row) => row?.email,
                },
                {
                  name: 'Contato',
                  cell: (row) => row?.contato_cargo,
                  sortable: true,
                },
                {
                  name: 'Cargo',
                  cell: (row) => row?.cargo?.cargo,
                  sortable: true,
                },
                {
                  name: 'Data de criação',
                  cell: (row) =>
                    moment(row?.created_at).format('DD/MM/YYYY HH:mm'),
                  sortable: true,
                },
                {
                  name: 'Ações',
                  cell: (row) => ActionList(row),
                },
              ]}
              paginationTotalRows={
                data?.empresarial_getCorretores?.paginatorInfo?.total || 0
              }
              data={data?.empresarial_getCorretores?.data || []}
              dense
              paginationServer
              progressPending={loading}
              onChangePage={onChagePage}
              paginationPerPage={15}
            />
          </CustomStyleDataTable>
        </ColaboradoresTabela>
      </IfComponent>
      {/* QUANDO FOR CADASTRAR UM NOVO CORRETOR */}
      <IfComponent hide={!novaCorretor}>
        <CadastrarNovoCorretor
          setNovaCorretor={setNovaCorretor}
          listarCorretores={listarCorretores}
          equipe_venda_id={equipe_venda_id}
        />
      </IfComponent>
      {/* QUANDO FOR EDITAR UM CORRETOR */}
    </ContentGeral>
  );
}
