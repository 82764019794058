/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */

import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import DataTable from 'react-data-table-component';
import useAuth from '~/config/auth_provider/contexto';
import {
  LISTAR_LINKS_VENDA,
  LISTAR_LINKS_VENDA_USER_LOGADO,
} from '~/config/gql';
import InputRound from '~/components/input_round';
import Header from '../layouts/header';
import { columns, paginationOptions, customStyles } from './constantes';
import {
  BoxTabs,
  ColaboradoresTabela,
  Content,
  CustomStyleDataTable,
} from './styles';
import IfComponent from '~/components/if_component';
import LoadingContent from '~/components/loading';

export default function LinksVenda() {
  const page = useRef(1);
  const [equipe, setEquipe] = useState(undefined);

  const { addToast } = useToasts();
  const dataItems = useAuth();
  const {
    auth_object: auth,
    me,
    loading_me,
    equipe_vendas,
    empresa_ativa,
  } = dataItems;
  const { equipes, firstElement } = equipe_vendas;
  const empresa_id = empresa_ativa?.id || firstElement?.id;

  const [
    getEquipes,
    { loading: loadingEquipe, data: dataEquipe },
  ] = useLazyQuery(LISTAR_LINKS_VENDA_USER_LOGADO, {
    context: {
      headers: {
        Authorization: auth.access_token,
      },
    },
    fetchPolicy: 'no-cache',
    skip: !auth.access_token || !me?.id || !empresa_ativa.id,
  });
  const equipeId = equipe || dataEquipe?.getUserEquipes?.[0]?.id;

  const [getLinks, { loading, data }] = useLazyQuery(LISTAR_LINKS_VENDA, {
    context: {
      headers: {
        Authorization: auth.access_token,
      },
    },
    fetchPolicy: 'no-cache',
    skip: loadingEquipe || !auth.access_token || !empresa_ativa.id,
  });

  useEffect(() => {
    if (empresa_id) {
      getEquipes({
        variables: {
          user_id: me?.id,
          empresa_id,
        },
      });
    }
  }, [dataItems]);

  useEffect(() => {
    if (empresa_id) {
      getLinks({
        variables: {
          page: page.current,
          first: 15,
          empresas: empresa_id,
        },
      });
    }
  }, [dataEquipe]);

  useEffect(() => {
    page.current = 1;
    getLinks({
      variables: {
        page: page.current,
        first: 15,
        empresas: empresa_id,
      },
    });
  }, [equipe]);

  const onChagePage = (pg) => {
    page.current = pg;
    getLinks({
      variables: {
        first: 15,
        page: page.current,
        empresas: empresa_id,
      },
    });
  };

  return (
    <Content>
      <Header
        title="Links de venda"
        texto={
          dataEquipe?.getUserEquipes?.length === 0 &&
          'Se vincule a alguma equipe de vendas para visualizar seus links.'
        }
      />
      <LoadingContent loading={loadingEquipe} />
      <IfComponent
        hide={dataEquipe?.getUserEquipes?.length === 0 || loadingEquipe}
      >
        <ColaboradoresTabela>
          <CustomStyleDataTable>
            <div className="control-input">
              <BoxTabs>
                <InputRound label="Equipes">
                  <select
                    disabled={loading}
                    onChange={(e) => {
                      const item = e?.target?.value || null;

                      if (item && item !== 'Selecionar') {
                        setEquipe(item);
                      } else {
                        setEquipe(undefined);
                      }
                    }}
                  >
                    {dataEquipe?.getUserEquipes?.map?.((iEquipe) => (
                      <option
                        selected={equipe === iEquipe.id}
                        value={iEquipe.id}
                      >
                        {iEquipe?.equipe_vendas?.ds_equipe}
                      </option>
                    ))}
                  </select>
                </InputRound>
              </BoxTabs>
            </div>

            {/* <LoadingContent loading={loading}> */}
            <DataTable
              noHeader
              pagination
              paginationComponentOptions={paginationOptions}
              noDataComponent="Nenhum link encontrado"
              columns={columns({
                addToast,
                userid: me?.id,
                corretor_id:
                  equipe || dataEquipe?.getUserEquipes?.[0]?.id || '',
                nome_completo: me?.nome_completo,
                ds_equipe:
                  dataEquipe?.getUserEquipes?.filter(
                    (item) => item.id == equipeId
                  )?.[0]?.equipe_vendas?.ds_equipe || '-',
                nm_empresa: equipes?.filter((item) => item.id == equipeId)?.[0]
                  ?.empresa?.nm_fantasia,
              })}
              data={data?.empresarial_getLinks?.data || []}
              customStyles={customStyles}
              dense
              paginationServer
              progressPending={loading || loading_me}
              paginationTotalRows={
                data?.empresarial_getLinks?.paginatorInfo?.total || 0
              }
              onChangePage={onChagePage}
              paginationPerPage={15}
            />
            {/* </LoadingContent> */}
          </CustomStyleDataTable>
        </ColaboradoresTabela>
      </IfComponent>
    </Content>
  );
}
